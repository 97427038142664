export const round = (n: number, d: number) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d);

export const getAuth0AccessToken = (): string | undefined => {
    let auth0Key: string | undefined = undefined;
    for (var i = 0; i <= sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key && key.includes("oidc.user:https://auth0.netrush.com/")) {
            auth0Key = key;
            break;
        }
    }

    if (auth0Key) {
        let auth0Value = sessionStorage.getItem(auth0Key);

        if (auth0Value) {
            let auth0 = JSON.parse(auth0Value)

            return auth0.access_token
        }
    }

    return undefined;
}

export const getSessionStorageKeys = (): string[] => {
    let keys: string[] = [];
    for (var i = 0; i <= sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key) {
            keys.push(key)
        }
    }

    return keys;
}