
import React from "react";
import ReactDOM from "react-dom";
//@ts-ignore
import reactToWebComponent from "react-to-webcomponent";
import HelloWorld from "./HelloWorld";
import MarketingKeywordTable from "./Marketing/MarketingKeywordTable";
import MarketingSpendChart from "./Marketing/MarketingSpendChart";

customElements.define(
    "hello-world",
    reactToWebComponent(HelloWorld, React, ReactDOM)
);

customElements.define(
    "marketing-spend-chart",
    reactToWebComponent(MarketingSpendChart, React, ReactDOM)
);

customElements.define(
    "marketing-keyword-table",
    reactToWebComponent(MarketingKeywordTable, React, ReactDOM)
);