
import React from "react";
import ReactDOM from "react-dom";
//@ts-ignore
import reactToWebComponent from "react-to-webcomponent";
import HelloWorld from "./HelloWorld";
import InventoryReportChart from "./Inventory/InventoryReportChart";
import InventoryReportTable from "./Inventory/InventoryReportTable";
import MarketingKeywordTable from "./Marketing/MarketingKeywordTable";
import MarketingSpendChart from "./Marketing/MarketingSpendChart";

customElements.define(
    "hello-world",
    reactToWebComponent(HelloWorld, React, ReactDOM)
);

//#region Marketing
customElements.define(
    "marketing-spend-chart",
    reactToWebComponent(MarketingSpendChart, React, ReactDOM)
);

customElements.define(
    "marketing-keyword-table",
    reactToWebComponent(MarketingKeywordTable, React, ReactDOM)
);
//#endregion

//#region Inventory
customElements.define(
    "inventory-report-chart",
    reactToWebComponent(InventoryReportChart, React, ReactDOM)
);

customElements.define(
    "inventory-report-table",
    reactToWebComponent(InventoryReportTable, React, ReactDOM)
);
//#endregion