import axios, { AxiosResponse } from "axios";
import { StatusCodes } from 'http-status-codes';
import { useState } from "react";
import { handleResponse } from "../helpers/axios";
import { getAuth0AccessToken } from "../helpers/Helpers";
import { useEffectAsync } from "./async";

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}

export interface XHRError extends Error {
    status: StatusCodes
}

export interface ResponseValues<TResponse, TBody> {
    data?: TResponse
    loading: boolean
    error?: XHRError
    response?: AxiosResponse<TResponse, TBody>
}

export const useRedshiftQueryClient = <TResult extends any>(url: string, method: Method, body?: any): [ResponseValues<TResult, any>] => {
    const [response, setResponse] = useState<TResult | undefined>();
    const [error, setError] = useState<XHRError | undefined>();
    const [loading, setLoading] = useState(true);

    const accessToken = getAuth0AccessToken();

    const client = axios.create({
        baseURL: "https://stitch360appfunappredshiftquery.azurewebsites.net/api/",
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    client.interceptors.response.use(handleResponse);

    const fetchData = async () => {
        try {
            const result = await client.request<TResult>({
                method: method,
                url: url,
                data: body
            });
            setResponse(result.data);
        } catch (e) {
            setError(JSON.parse(JSON.stringify(e)) as XHRError);
        } finally {
            setLoading(false);
        }
    };

    useEffectAsync(async () => {
        await fetchData();
    }, []);

    return [{ data: response, error, loading }];
}