import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    name: PropTypes.string.isRequired
}

type HelloWorldProps = PropTypes.InferProps<typeof propTypes>;

const HelloWorld: React.FC<HelloWorldProps> = ({ name }) => {
    return <div>Hello, {name}!</div>;
}

HelloWorld.propTypes = propTypes;

export default HelloWorld;
