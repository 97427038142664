import { useEffect, useState } from "react";
import { Pagination as BSPagination } from "react-bootstrap";

interface PaginationProps {
    activePage: number;
    pageCount: number;
    onSelect: (page: number) => void;
    className?: string;
}

const Pagination: React.FC<PaginationProps> = ({ activePage, pageCount, onSelect, className }) => {
    const [displayArray, setDisplayArray] = useState<number[]>([]);

    const updateDisplayArray = () => {
        if (pageCount) {
            let adjustedPage = activePage + 1;
            let value = Math.min(pageCount - 4, Math.max(adjustedPage - 2, 1));
            let array: number[] = [];

            for (let i = 1; i <= Math.min(pageCount, 5); i++) {
                array.push(value);
                value++;
            }

            setDisplayArray(array);
        }
    };

    useEffect(() => {
        updateDisplayArray();

        console.log("useEffect.displayArray: ", displayArray)
    }, [activePage, pageCount])

    let items: React.ReactNode[] = [];

    if (pageCount && !displayArray.includes(1)) {
        items.push(<BSPagination.Item onClick={() => onSelect(0)}>{1}</BSPagination.Item>)
        items.push(<BSPagination.Ellipsis />)
    };

    displayArray.forEach((page, i) => {
        items.push(<BSPagination.Item active={page == activePage + 1} onClick={() => onSelect(page - 1)}>{page}</BSPagination.Item>);
    })

    if (pageCount && !displayArray.includes(pageCount)) {
        items.push(<BSPagination.Ellipsis />)
        items.push(<BSPagination.Item onClick={() => onSelect(pageCount - 1)}>{pageCount}</BSPagination.Item>)
    }

    return (
        <BSPagination className={className}>
            <BSPagination.Prev disabled={activePage + 1 === 1} onClick={() => onSelect(activePage - 1)} />
            {items}
            <BSPagination.Next disabled={activePage + 1 === pageCount} onClick={() => onSelect(activePage + 1)} />
        </BSPagination>
    )
}

export default Pagination;