import { StatusCodes } from "http-status-codes";
import { chunk } from "lodash";
import PropTypes from 'prop-types';
import { useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import { round } from "../../helpers/Helpers";
import { Method, useRedshiftQueryClient } from "../../hooks/clients";
import Pagination from '../Pagination';
import { AmazonAdvKeywordStatsBase, GetAmazonAdvKeywordStatsRequest } from "./Shared";

interface AmazonAdvKeywordStatsTabular extends AmazonAdvKeywordStatsBase {
    keyword: string;
    impressions: number;
    clicks: number;
    ctr: number;
    cpc: number;
    conversionRate: number;
}

const propTypes = {
    rlskeys: PropTypes.string.isRequired
}

type MarketingKeywordTableProps = PropTypes.InferProps<typeof propTypes>;

const MarketingKeywordTable: React.FC<MarketingKeywordTableProps> = ({ rlskeys }) => {
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);

    let body: GetAmazonAdvKeywordStatsRequest = {
        RlsKeys: JSON.parse(rlskeys) as string[]
    }

    const [{ data: tableData, loading: isLoading, error }] =
        useRedshiftQueryClient<AmazonAdvKeywordStatsTabular[]>("AmazonAdvKeywordStats/Tabular", Method.POST, body);

    const sizes: number[] = [10, 25, 50, 100];

    const chunks = tableData ? chunk(tableData, size) : [];

    let errorMessage: string | undefined = undefined;

    if (error) {
        switch (error.status) {
            case StatusCodes.FORBIDDEN:
                errorMessage = "Authentication failed";
                break;
            default:
                errorMessage = "An error occurred"
                break;
        }
    }

    return (
        <Card className={`h-100 scrolling no-padding ${(isLoading && "loading")}`}>
            <Card.Header className="bg-light d-flex justify-content-between align-items-center">
                <Form>
                    <Form.Select value={size} onChange={(v) => setSize(parseInt(v.target.value))}>
                        {sizes.map(o =>
                            <option value={o}>{o}</option>
                        )}
                    </Form.Select>
                </Form>
                <h5 className="mb-0 text-center">KEYWORD DATA <small>{tableData?.length}</small></h5>
                <Pagination className="m-0" activePage={page} onSelect={(page) => setPage(page)} pageCount={chunks.length} />
            </Card.Header>
            <Card.Body>
                {errorMessage && <h3 className="text-center">{errorMessage}</h3>}
                {chunks.length > 0 &&
                    <Table striped className="scrolling">
                        <thead>
                            <tr>
                                <th className="text-center" scope="col">Keyword</th>
                                <th className="text-center" scope="col">Spend</th>
                                <th className="text-center" scope="col">Attributed Sales</th>
                                <th className="text-center" scope="col">ACoS</th>
                                <th className="text-center" scope="col">Impressions</th>
                                <th className="text-center" scope="col">Clicks</th>
                                <th className="text-center" scope="col">CTR</th>
                                <th className="text-center" scope="col">CPC</th>
                                <th className="text-center" scope="col">Conversion Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chunks[page].map(item =>
                                <Row {...item} />
                            )}
                        </tbody>
                    </Table>
                }
            </Card.Body>
        </Card>
    );
}

MarketingKeywordTable.propTypes = propTypes;

export default MarketingKeywordTable;

const Row: React.FC<AmazonAdvKeywordStatsTabular> = (data) =>
    <tr>
        <td>{data.keyword}</td>
        <td className="text-center">{round(data.spend, 4)}</td>
        <td className="text-center">{round(data.attributedSales, 4)}</td>
        <td className="text-center">{round(data.aCoS, 4)}</td>
        <td className="text-center">{data.impressions}</td>
        <td className="text-center">{data.clicks}</td>
        <td className="text-center">{round(data.ctr, 4)}</td>
        <td className="text-center">{round(data.cpc, 4)}</td>
        <td className="text-center">{round(data.conversionRate, 4)}</td>
    </tr>