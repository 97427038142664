import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { InventoryReportRequest } from './Shared';

const propTypes = {
    filters: PropTypes.string.isRequired
}

type InventoryReportTableProps = PropTypes.InferProps<typeof propTypes>;

const InventoryReportTable: React.FC<InventoryReportTableProps> = ({ filters: filtersFromProps }) => {
    console.log("InventoryReportTable.filtersFromProps", filtersFromProps);
    const filters = JSON.parse(filtersFromProps) as InventoryReportRequest;

    const [isLoading, setIsLoading] = useState(false);

    return (
        <Card className={`h-100 ${(isLoading && "loading")}`}>
            <Card.Header className="bg-light">
                <h5 className="mb-0 text-center">Inventory Table</h5>
            </Card.Header>
            <Card.Body>
                <pre>
                    {JSON.stringify(filters, null, 2)}
                </pre>
            </Card.Body>
        </Card>
    )
}

InventoryReportTable.propTypes = propTypes;

export default InventoryReportTable;