import axios from 'axios';
import { BarController, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js';
import { format } from 'date-fns';
import { StatusCodes } from 'http-status-codes';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Chart } from 'react-chartjs-2';
import { round } from '../../helpers/Helpers';
import { Method, useRedshiftQueryClient, XHRError } from '../../hooks/clients';
import { AmazonAdvKeywordStatsBase, GetAmazonAdvKeywordStatsRequest } from './Shared';

ChartJS.defaults.font.family = '"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarController,
    BarElement,
    PointElement,
    LineController,
    LineElement,
    Legend,
    Tooltip
);

interface AmazonAdvKeywordStatsSummary extends AmazonAdvKeywordStatsBase {
    statsDate: Date;
}

const colors = ["#1C1042", "#42042C", "#FC8575"];

const propTypes = {
    rlskeys: PropTypes.string.isRequired
}

type MarketingSpendChartProps = PropTypes.InferProps<typeof propTypes>;

const MarketingSpendChart: React.FC<MarketingSpendChartProps> = ({ rlskeys }) => {
    let body: GetAmazonAdvKeywordStatsRequest = {
        RlsKeys: JSON.parse(rlskeys) as string[]
    }

    const [{ data: summaries, loading: isLoading, error }] = useRedshiftQueryClient<AmazonAdvKeywordStatsSummary[]>("AmazonAdvKeywordStats/Summaries", Method.POST, body);

    var chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    }

    const labels = summaries?.map(x => format(x.statsDate, "E dd"));
    const dataOptions = {
        labels,
        datasets: [
            {
                type: 'line' as const,
                label: 'ACoS',
                data: summaries?.map(x => round(x.aCoS, 4)),
                yAxisID: 'y1',
                tension: 0.25,
                pointRadius: 0,
                backgroundColor: colors[2],
                borderColor: colors[2]
            },
            {
                type: 'bar' as const,
                label: 'Spend',
                data: summaries?.map(x => round(x.spend, 4)),
                yAxisID: 'y',
                backgroundColor: colors[0]
            },
            {
                type: 'bar' as const,
                label: 'Attributed Sales',
                data: summaries?.map(x => round(x.attributedSales, 4)),
                yAxisID: 'y',
                backgroundColor: colors[1]
            }
        ]
    }

    let errorMessage: string | undefined = undefined;

    if (error) {
        switch (error.status) {
            case StatusCodes.FORBIDDEN:
                errorMessage = "Authentication failed";
                break;
            default:
                errorMessage = "An error occurred"
                break;
        }
    }

    return (
        <Card className={`h-100 ${(isLoading && "loading")}`}>
            <Card.Header className="bg-light">
                <h5 className="mb-0 text-center">MARKETING SPEND & RETURN OVER TIME</h5>
            </Card.Header>
            <Card.Body>
                {errorMessage && <h3 className="text-center">{errorMessage}</h3>}
                {(summaries?.length ?? 0) > 0 &&
                    <Chart type='bar' data={dataOptions} options={chartOptions} />
                }
            </Card.Body>
        </Card>
    )
}

MarketingSpendChart.propTypes = propTypes;

export default MarketingSpendChart;